import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function Post(props) {
  return (
    <Card className="m-2">
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text>{props.body}</Card.Text>
        <small className="card-text text-muted">{props.user}</small>
      </Card.Body>
    </Card>
  );
}

function Posts(props) {
  return (
    <Container className="mt-4">
      {props.posts.map(post =>
        <Row key={post.id} className="justify-content-center">
          <Col sm={8} md={8} lg={8} xl={8} xxl={8}>
            <Post title={post.title} body={post.content} user={post.username} />
          </Col>
        </Row>)}
    </Container>
  );
}

export { Post, Posts };
