import './App.css';

import React, { useEffect, useState } from "react";

import Nav from './Navbar';
import CreatePost from './CreatePost';
import { Posts } from './Post';

const guestUsername = "u";
const username = guestUsername + Math.floor(Math.random() * 10000);

function App() {
  const [posts, setPosts] = useState([]);

  const getPosts = async () => {
    const resp = await fetch("https://general-worker.varunven.workers.dev/posts");
    const postsResp = await resp.json();

    postsResp.sort((a, b) => b.time - a.time);

    setPosts(postsResp);
  };

  useEffect(() => {
    getPosts();
  }, []);

  const refresh = (post) => {
    let updatedPosts = [...posts, ...post];
    updatedPosts.sort((a, b) => b.time - a.time);

    setPosts(updatedPosts);
  }

  return (
    [<Nav key="nav" username={username}/>,
    <CreatePost key="createpost" refresh={refresh} username={username}/>,
    <Posts key="posts" posts={posts}/>]
  );
}

export default App;
