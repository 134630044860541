import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';

function Nav(props) {
  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>Cloudflare General Assignment</Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>{props.username}</Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Nav;
