import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';

import React, { useState } from "react";

function CreatePost(props) {
  const [report, setReport] = useState({status: '', message: ''});

  const postData = async (url = '', data = {}) => {
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.status < 300) {
      setReport({status: 'text-success', message: 'Post created successfully!'});
      return response.json();
    }
    else {
      setReport({status: 'text-danger', message: 'Post creation failed!'});
      return Promise({error: 'Post creation failed!'});
    }

  }

  const submitPost = async (event) => {
    event.preventDefault();

    let form = event.target

    let post = {
      title: form.elements.title.value,
      content: form.elements.content.value,
      username: props.username,
      time: new Date().getTime()
    }

    post = await postData(
      "https://general-worker.varunven.workers.dev/posts",
      post 
    );

    form.reset();

    if (!post.hasOwnProperty('error')) {
      props.refresh(post);
    }
  }


  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col sm={8} md={8} lg={8} xl={8} xxl={8}>
          <Accordion className="border border-info text-center">
            <Accordion.Header>Want to create a post?</Accordion.Header>
            <Accordion.Body>
              <Form onSubmit={submitPost}>
                <Form.Control className="mb-3" placeholder="Title" maxLength="200" name="title" />
                <Form.Control className="mb-3" as="textarea" placeholder="Content" maxLength="1000" rows="5" name="content" />
                <Button className="w-100" variant="primary" type="submit">Post</Button>
                <Form.Text className={report.status + " mb-3"}>{report.message}</Form.Text>
              </Form>
            </Accordion.Body>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
}

export default CreatePost;
